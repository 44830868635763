<template>
  <div>
    <div v-if="show || $store.state.isModIn">
      <button @click="logout" class="btn btn-primary float-right">
        Log Out
      </button>
      <h1>Admin Time!!!</h1>
      <p>
        You're now in mod mode.
        <strong
          >The app now remembers you are a mod so you don't have to log in
          again.</strong
        >
      </p>
      <div class="columns">
        <div class="column col-6 col-xs-12">
          <h3>Letter Checking Status</h3>

          <div class="form-group">
            <label class="form-label" for="letter-check-user"
              >Last user checked:</label
            >
            <input
              id="letter-check-user"
              type="text"
              class="form-input"
              v-model="lastLetterChecked"
            />
            <label class="form-label" for="letter-check-notes">Notes</label>
            <textarea
              name="letter-check-notes"
              id="letter-check-notes"
              rows="3"
              class="form-input"
              v-model="note"
            >
            </textarea>
            <button class="btn my-2" @click="setLetterNote">Update</button>
          </div>
        </div>
      </div>

      <h3>Letter Keys</h3>
      <button class="btn my-2" @click="getLetterkeys">
        Get/Refresh Letterkeys
      </button>
      <div v-if="letterkeys && !msg.length">
        <h4>Total: {{ Object.keys(letterkeys).length }}</h4>
        <ul class="letterkeys">
          <li v-for="(data, user) in letterkeys" :key="user">
            <code>{{ user }}</code>
            : {{ data.key }}
          </li>
        </ul>
      </div>
      <div v-else>{{ this.msg }}</div>
    </div>
    <div v-if="!show && !$store.state.isModIn">
      <h3>Speak, friend, and enter</h3>
      <form>
        <input
          class="form-input"
          type="password"
          placeholder="Passphrase"
          v-model="passcode"
          autocomplete="current-password"
          id="key"
          @keydown.enter="open"
        />
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      passcode: "",
      show: false,
      letterkeys: null,
      msg: "",
      note: "",
      lastLetterChecked: ""
    };
  },
  created() {
    if (this.$store.state.isModIn) {
      this.getLetterNote();
    }
  },
  methods: {
    open() {
      if (this.passcode == "modmodmod") {
        this.show = true;
        this.$store.commit("setModIn", true);
        this.$store.commit("setHasLetters", true);
        this.$localStorage.set("isModIn", true);
        this.getLetterNote();
      }
    },
    logout() {
      this.$localStorage.set("isModIn", false);
      this.$store.commit("setModIn", false);
      this.$store.commit("setHasLetters", false);

      this.show = false;
    },
    getLetterkeys() {
      axios
        .get("https://tagset2023-default-rtdb.firebaseio.com/letterkeys.json")
        .then(res => {
          this.letterkeys = res.data;

          if (!this.letterkeys) {
            this.msg = "No letters yet :(";
          }
        });
    },
    setLetterNote() {
      axios.put(
        `https://tagset2023-default-rtdb.firebaseio.com/admin/letterCheck.json`,
        {
          lastChecked: this.lastLetterChecked,
          note: this.note
        }
      );
    },
    getLetterNote() {
      axios
        .get(
          `https://tagset2023-default-rtdb.firebaseio.com/admin/letterCheck.json`
        )
        .then(res => {
          if (!res.data) {
            return;
          }
          this.note = res.data.note;
          this.lastLetterChecked = res.data.lastChecked;
        })
        .catch(err => {
          this.note = "Failed to load! D:";
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.letterkeys {
  height: 550px;
  overflow-y: auto;
}
</style>
